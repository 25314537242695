import { toast } from 'react-toastify';

class ApiService {
	get(call) {
		return this.makeApiCall(call, 'GET');
	}

	post(call, data) {
		return this.makeApiCall(call, 'POST', data);
	}

	put(call, data) {
		return this.makeApiCall(call, 'PUT', data);
	}

	delete(call) {
		return this.makeApiCall(call, 'DELETE');
	}

	makeApiCall(call, method, data) {
		console.log(process.env.REACT_APP_API_ENDPOINT)
		data = JSON.stringify(data);

		return new Promise((resolve, reject) => {
			fetch(process.env.REACT_APP_API_ENDPOINT + call, {
				method,
				body: data,
				headers: { 'content-type': 'application/json' },
				credentials: 'include',
			})
				.then((res) => {
					if (res.status === 401 || res.status === 403) {
						const arr = [
							'/login',
							'/get-reset-password-email',
							'/reset-password-with-token',
							'/signup',
						];
						if (!arr.includes(window.location.pathname)) {
							//can't to it with urlsearchparams because it encodes the confirm-email token and the backend takes it as invalid
							let params = searchParamsToObjectWithoutUrlEncoding();
							params.next = window.location.pathname;
							const url = `/login?${objectToSearchStringWithoutEncoding(
								params
							)}`;
							window.location = url;
						}
					}
					return res;
				})
				.then(async (res) => {
					if (res.ok) return res;
					else throw await this.jsonParser(res);
				})
				.then((res) => {
					if (res.status === 204) return;
					else return this.jsonParser(res);
				})
				.then(resolve)
				.catch((err) => {
					if (err.errors) {
						for (const key in err.errors) {
							toast.error(err.errors[key].join(', '));
						}
					} else {
						// toast.error('Error');
					}
					reject(err);
				});
		});
	}

	JSONDateParser(key, value) {
		// const reISO = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
		const reISO =
			/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
		if (typeof value === 'string') {
			if (reISO.exec(value)) {
				// is date
				return new Date(value);
			}
		}
		return value;
	}

	async jsonParser(response) {
		const text = await response.text();
		console.time('json with date');
		try {
			let json = JSON.parse(text, this.JSONDateParser);
			console.timeEnd('json with date');
			return json;
		} catch (error) {
			return text;
		}
	}
}
export let apiService = new ApiService();

export function searchParamsToObjectWithoutUrlEncoding(
	str = window.location.search
) {
	if (!str) return {};
	str += '?';
	const keys = str.match(/[?&].+?=/g);
	const values = str.match(/=.+?[?&]/g);
	const obj = {};
	keys.map((e, i) => {
		obj[e.slice(1).slice(0, -1)] = values[i].slice(1).slice(0, -1);
	});
	return obj;
}

export function objectToSearchStringWithoutEncoding(params) {
	return Object.entries(params)
		.map(([key, val]) => `${key}=${val}`)
		.join('&');
}